import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { usePage, useForm } from "@inertiajs/react";
import { ActionButton, BasicModal, Container, Table, BasicRow, RawCell, CellHeader, TextCell, ActionButtonsCell, Form, TextareaComponent, ActionHeader, } from "@truenorthmortgage/olympus";
import { store, pushNotification } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertFromUTCtoLocalTime, getFormattedDateTime, } from "../../../utils/utils";
import "./Notes.scss";
const Notes = ({ model_uuid = null, notes = [], callback = null, model = null, }) => {
    const { flash, auth } = usePage().props;
    const modalRef = useRef(null);
    const [modalNotes, setModalNotes] = useState(notes !== null && notes !== void 0 ? notes : []);
    const [editingNote, setEditingNote] = useState(null);
    const { data, setData, post, processing, errors, setError, reset, clearErrors, delete: destroy, } = useForm({
        uuid: null,
        model_uuid: model_uuid,
        note: "",
        model: model,
    });
    if (model_uuid !== null && model === null) {
        throw new Error("To use the model_uuid parameter, the model parameter must also be passed to the Notes component");
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.note === null || data.note === "") {
            setError("note", "required");
            store.dispatch(pushNotification({
                message: "Note cannot be blank.",
                class: "error",
            }));
            return;
        }
        else {
            clearErrors();
        }
        // Save the note using the backend request if we have the model_uuid and the model.
        if (model_uuid !== null) {
            post("/notes/save", {
                preserveScroll: true,
            });
        }
        // Save the note into the react notes object to it can be submitted by the parent form request.
        if (model_uuid === null) {
            addNote();
            store.dispatch(pushNotification({
                message: "Note successfully saved.",
                class: "success",
            }));
            resetForm();
        }
    };
    useEffect(() => {
        if (flash === null || flash === void 0 ? void 0 : flash.success) {
            if (callback) {
                callback();
            }
            addNote();
            resetForm();
        }
    }, [flash]);
    const handleModal = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    const addNote = () => {
        var _a, _b, _c, _d, _e, _f;
        // Validate if the note has value.
        if (data.note === "" || data.note === null || data.note === undefined) {
            return;
        }
        // Update if it has editing note.
        if (editingNote !== null) {
            updateNote();
            return;
        }
        const note = {
            uuid: (_c = (_b = (_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.note) === null || _b === void 0 ? void 0 : _b.uuid) !== null && _c !== void 0 ? _c : null,
            note: data.note,
            internal: (_f = (_e = (_d = flash === null || flash === void 0 ? void 0 : flash.form) === null || _d === void 0 ? void 0 : _d.note) === null || _e === void 0 ? void 0 : _e.internal) !== null && _f !== void 0 ? _f : true,
            user: { firstname: auth.user.first_name },
            user_uuid: auth.user.id,
            formatted_date: getFormattedDateTime(),
            created_at: new Date(),
        };
        // Create note
        if (data.uuid === null) {
            setModalNotes([note, ...modalNotes]);
            notes = [note, ...modalNotes];
        }
        if (callback !== null) {
            callback(notes);
        }
    };
    const updateNote = () => {
        if (editingNote === null) {
            return;
        }
        const updated_notes = modalNotes.map((item) => item === editingNote
            ? Object.assign(Object.assign({}, item), { note: data === null || data === void 0 ? void 0 : data.note, formatted_date: getFormattedDateTime() }) : item);
        setModalNotes(updated_notes);
        notes = updated_notes;
        if (callback !== null) {
            callback(notes);
        }
    };
    const editNote = (note) => {
        setEditingNote(note);
        setData(note);
        handleModal();
    };
    const deleteNote = (note) => {
        if (confirm("Are you sure you want to delete the note?")) {
            // Remove the note deleted from the array if the back-end response equals success.
            if (note.uuid !== null) {
                destroy("/notes/" + note.uuid, {
                    preserveScroll: true,
                    onSuccess: () => removeNoteFromUi(note),
                });
            }
            else {
                // Remove the note deleted from the array.
                removeNoteFromUi(note);
            }
        }
    };
    const removeNoteFromUi = (note) => {
        const filtered_notes = [
            ...modalNotes.filter((modal_note) => modal_note !== note),
        ];
        notes = filtered_notes;
        setModalNotes(notes);
        if (callback !== null) {
            callback(notes);
        }
    };
    const actions = (note) => {
        const actions = [
            _jsx(ActionButton, Object.assign({ className: "green", title: "Edit Note", callback: () => editNote(note) }, { children: _jsx(FontAwesomeIcon, { icon: "pencil" }) }), "action-view"),
            _jsx(ActionButton, Object.assign({ className: "red", title: "Delete User", callback: () => deleteNote(note) }, { children: _jsx(FontAwesomeIcon, { icon: "trash" }) }), "action-delete"),
        ];
        return actions;
    };
    const resetForm = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
        setEditingNote(null);
        reset();
    };
    const getUserFormattedDate = (updated_at) => {
        return getFormattedDateTime(convertFromUTCtoLocalTime(updated_at));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "full" }, { children: [_jsx("div", Object.assign({ className: "side-by-side-element" }, { children: _jsx(ActionHeader, { title: "Notes", widgetClassName: "no-margin no-padding" }, "notes-action-header") })), _jsx("div", Object.assign({ className: "side-by-side-element right", style: { marginTop: "-10px" } }, { children: _jsxs(ActionButton, Object.assign({ title: "Add Note", callback: handleModal, className: "green" }, { children: [_jsx(FontAwesomeIcon, { icon: "plus" }), _jsx(FontAwesomeIcon, { icon: "note-sticky", style: { marginLeft: 5 } })] }), "add-note-button") }))] })), _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Empty", isEmpty: (modalNotes === null || modalNotes === void 0 ? void 0 : modalNotes.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "notes-header section-header" }, { children: [_jsx(RawCell, { children: "Date/Time" }), _jsx(RawCell, { children: "User" }), _jsx(RawCell, { children: "Note" }), _jsx(TextCell, {})] })), modalNotes === null || modalNotes === void 0 ? void 0 : modalNotes.map((note, index) => {
                        var _a, _b, _c, _d, _e;
                        return (_jsxs(BasicRow, Object.assign({ id: index }, { children: [_jsx(TextCell, { children: (_a = note.formatted_date) !== null && _a !== void 0 ? _a : getUserFormattedDate((_b = note.updated_at) !== null && _b !== void 0 ? _b : null) }), _jsx(TextCell, { children: (_d = (_c = note === null || note === void 0 ? void 0 : note.user) === null || _c === void 0 ? void 0 : _c.firstname) !== null && _d !== void 0 ? _d : "" }), _jsx(TextCell, Object.assign({ className: "pre-line" }, { children: (_e = note.note) !== null && _e !== void 0 ? _e : "" })), _jsx(ActionButtonsCell, { children: actions(note) })] }), index));
                    })] })), _jsx("div", Object.assign({ className: "modal-notes" }, { children: _jsxs(BasicModal, Object.assign({ ref: modalRef, onClose: resetForm, title: "Add Note" }, { children: [_jsx("div", Object.assign({ className: "center" }, { children: _jsx("h2", { children: "Add a Note" }) })), _jsx(Container, { children: _jsxs(Form, Object.assign({ className: "modal-form-notes" }, { children: [_jsx(TextareaComponent, { label: "Add a Note", defaultValue: data.note, value: data.note, onBlur: (value) => setData("note", value), error: (errors === null || errors === void 0 ? void 0 : errors.note) ? true : false }), _jsx("div", Object.assign({ className: "column right" }, { children: _jsxs("button", Object.assign({ type: "button", style: { marginTop: 0, marginRight: 0 }, disabled: processing, className: "button submit-button", onClick: (e) => handleSubmit(e) }, { children: [_jsx(FontAwesomeIcon, { icon: "save" }), " Save"] })) }))] })) })] })) }))] }));
};
export default Notes;
