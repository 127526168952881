import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { router, usePage } from "@inertiajs/react";
import { Container, Column, Form, SelectComponent, BaseInputComponent, CheckboxSelectComponent, CheckboxOption, DateComponent, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BankAccountForm.scss";
const BankAccountSettingsForm = ({ active_bank_accounts = [], investment_account = null, callback = null, }) => {
    var _a;
    const { flash, errors, CONSTANTS } = usePage().props;
    const [bankAccountUUID, setBankAccountUUID] = useState((_a = investment_account === null || investment_account === void 0 ? void 0 : investment_account.preferred_auto_withdraw_bank_account_uuid) !== null && _a !== void 0 ? _a : "");
    const [auto_withdraw_enable, setAutoWithdrawEnable] = useState((investment_account === null || investment_account === void 0 ? void 0 : investment_account.preferred_auto_withdraw_bank_account_uuid)
        ? ["enabled"]
        : []);
    const [primary_investment_account_enable, setPrimaryInvestmentAccountEnable] = useState((investment_account === null || investment_account === void 0 ? void 0 : investment_account.primary) ? ["enabled"] : []);
    const [activationDate, setActivationDate] = useState(investment_account === null || investment_account === void 0 ? void 0 : investment_account.activation_date);
    useEffect(() => {
        if ((flash === null || flash === void 0 ? void 0 : flash.success) === "Investment account settings updated successfully.") {
            closeModel();
        }
    }, [flash]);
    const closeModel = () => {
        callback();
    };
    const updateBankAccount = (val) => {
        setBankAccountUUID(val);
    };
    const primaryInvestmentAccountEnableCallback = (val) => {
        setPrimaryInvestmentAccountEnable(val);
    };
    const autoWithdrawEnableCallback = (val) => {
        setAutoWithdrawEnable(val);
        if (Array.isArray(val) && val.length === 0) {
            updateBankAccount("");
        }
    };
    const updateAutoWithDrawSettings = () => {
        const submitValues = {
            bank_account_uuid: bankAccountUUID,
            investment_account_uuid: investment_account === null || investment_account === void 0 ? void 0 : investment_account.uuid,
            enable_auto_withdraw: auto_withdraw_enable.includes("enabled"),
            primary_investment_account: primary_investment_account_enable.includes("enabled"),
            activation_date: activationDate,
        };
        router.post("/investors/investment-account/modal-account-settings", submitValues, {
            preserveScroll: true,
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "create-bank-account-form-container" }, { children: [_jsx(Column, Object.assign({ columnStyle: "full create-bank-account-form" }, { children: _jsx("div", Object.assign({ className: "center" }, { children: _jsx("h2", { children: "Account Settings" }) })) })), _jsx(Column, { children: _jsxs(Form, Object.assign({ className: "modal-form-edit-bank-account" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "full primary-account-settings" }, { children: [_jsx(Column, Object.assign({ columnStyle: "status-label" }, { children: _jsx("h2", { children: "Investment Account Settings" }) })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: _jsx("div", Object.assign({ className: "checkbox-wrap" }, { children: _jsx(CheckboxSelectComponent, Object.assign({ label: "Primary Investment Account", values: primary_investment_account_enable, onChange: primaryInvestmentAccountEnableCallback }, { children: _jsx(CheckboxOption, { label: "Enabled", value: "enabled" }) })) })) })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: _jsxs("div", Object.assign({ className: "date-icon-wrap" }, { children: [_jsx(FontAwesomeIcon, { icon: "calendar" }), _jsx(DateComponent, { id: "activation_date", dateFormat: "Y-m-d", onChange: (value) => setActivationDate(value), defaultValue: activationDate, value: activationDate, columnStyle: "default-investment side-by-side-element no-left-right-padding", label: "Activation Date*", placeholder: "Click to set a date", error: errors === null || errors === void 0 ? void 0 : errors.activation_date }, "activation_date")] })) }))] }), "form-columns-1-primary-account"), _jsx(Column, Object.assign({ columnStyle: "full auto-withdraw-settings" }, { children: ((investment_account === null || investment_account === void 0 ? void 0 : investment_account.type) ===
                                    CONSTANTS.INVESTMENT_ACCOUNT.TYPE.CASH.value ||
                                    (investment_account === null || investment_account === void 0 ? void 0 : investment_account.type) ===
                                        CONSTANTS.INVESTMENT_ACCOUNT.TYPE.TFSA.value) && (_jsxs(_Fragment, { children: [_jsx(Column, Object.assign({ columnStyle: "status-label" }, { children: _jsx("h2", { children: "Auto Withdraw Settings" }) })), _jsx(Column, { children: _jsx("div", Object.assign({ className: "checkbox-wrap" }, { children: _jsx(CheckboxSelectComponent, Object.assign({ label: "Auto Withdraw Enabled", values: auto_withdraw_enable, onChange: autoWithdrawEnableCallback }, { children: _jsx(CheckboxOption, { label: "Enabled", value: "enabled" }) })) })) }), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Investor Account" }), _jsx(BaseInputComponent, { id: "investment_account", inputValue: (investment_account === null || investment_account === void 0 ? void 0 : investment_account.type_label) +
                                                        " - " +
                                                        (investment_account === null || investment_account === void 0 ? void 0 : investment_account.friendly_id), disabled: true }, "investment_account")] })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element status-label" }, { children: _jsxs(SelectComponent, Object.assign({ label: "Auto Withdraw Bank Account", id: "auto_withdraw_preferred_account", defaultValue: bankAccountUUID !== null && bankAccountUUID !== void 0 ? bankAccountUUID : "", disabled: auto_withdraw_enable.includes("enabled") === false, onChange: updateBankAccount, error: errors === null || errors === void 0 ? void 0 : errors.auto_withdraw_preferred_account, value: bankAccountUUID }, { children: [_jsx("option", Object.assign({ id: "default_preferred_account", value: "" }, { children: "Auto Withdraw Bank Account" }), "default_preferred_account"), active_bank_accounts === null || active_bank_accounts === void 0 ? void 0 : active_bank_accounts.map((account) => (_jsx("option", Object.assign({ id: account === null || account === void 0 ? void 0 : account.uuid, value: account === null || account === void 0 ? void 0 : account.uuid }, { children: (account === null || account === void 0 ? void 0 : account.bank_number) +
                                                            " " +
                                                            (account === null || account === void 0 ? void 0 : account.account_number) +
                                                            " - " +
                                                            (account === null || account === void 0 ? void 0 : account.account_holder_name) }), (account === null || account === void 0 ? void 0 : account.uuid) + "tmp")))] }), "auto_withdraw_preferred_account_overview") }))] })) }), "form-columns-1"), _jsx(Column, { children: _jsxs("div", Object.assign({ className: "modal-bank-account-button side-by-side-element right", style: { display: "flex" } }, { children: [_jsx("button", Object.assign({ type: "button", className: "button submit-button red", style: { marginLeft: 0 }, onClick: closeModel }, { children: "Cancel" }), "add_bank_account_modal_cancel_button"), _jsxs("button", Object.assign({ type: "button", className: "button submit-button", style: { marginLeft: "auto" }, onClick: updateAutoWithDrawSettings }, { children: [_jsx(FontAwesomeIcon, { icon: "save" }, "investor-form-save"), "Save"] }), "add_bank_account_modal_create_button")] })) })] })) })] })) }));
};
export default BankAccountSettingsForm;
