import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { router, usePage } from "@inertiajs/react";
import { Container, Column, Form } from "@truenorthmortgage/olympus";
import { formatNumber, unitsDividedByMultiplier, } from "../../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CancelFeeForm = ({ journal_entry_item = null, callback = null, }) => {
    var _a, _b;
    const { flash } = usePage().props;
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        if (flash === null || flash === void 0 ? void 0 : flash.success) {
            if (callback) {
                callback();
            }
        }
    }, [flash === null || flash === void 0 ? void 0 : flash.success]);
    const cancelFeeCallback = () => {
        if (window.confirm("Do you really want to delete this Fee?")) {
            router.post("/fee/cancel", {
                journal_entry_uuid: journal_entry_item === null || journal_entry_item === void 0 ? void 0 : journal_entry_item.journal_entry_uuid,
            }, {
                onBefore: () => setProcessing(true),
                onFinish: () => {
                    setProcessing(false);
                },
                preserveScroll: true,
            });
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "generic-modal-container" }, { children: [_jsx(Column, Object.assign({ columnStyle: "full" }, { children: _jsx("div", Object.assign({ className: "center" }, { children: _jsxs("h2", { children: ["Cancel ", journal_entry_item === null || journal_entry_item === void 0 ? void 0 : journal_entry_item.type_label] }) })) })), _jsx(Column, { children: _jsxs(Form, Object.assign({ className: "modal-form-purchase-investment" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Type" }), _jsx("div", { children: journal_entry_item === null || journal_entry_item === void 0 ? void 0 : journal_entry_item.type_label })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Amount" }), _jsxs("div", { children: ["$", formatNumber((_b = unitsDividedByMultiplier((_a = journal_entry_item === null || journal_entry_item === void 0 ? void 0 : journal_entry_item.debit_amount) !== null && _a !== void 0 ? _a : journal_entry_item === null || journal_entry_item === void 0 ? void 0 : journal_entry_item.credit_amount, 100)) !== null && _b !== void 0 ? _b : 0)] })] })), _jsxs(Column, { children: [_jsx("label", { children: "Description" }), _jsx("div", { children: journal_entry_item === null || journal_entry_item === void 0 ? void 0 : journal_entry_item.description })] }), _jsxs(Column, { children: [_jsxs("div", Object.assign({ style: {
                                            lineHeight: 1.5,
                                            textAlign: "justify",
                                            marginBottom: 5,
                                            padding: "5px 0px 8px",
                                        } }, { children: [_jsx(FontAwesomeIcon, { icon: "exclamation-triangle", style: {
                                                    marginRight: 5,
                                                    color: "#F5A623",
                                                    fontSize: 17,
                                                    fontWeight: 900,
                                                } }), "Fees can only be cancelled if there are no associated EFT PADs marked as submitted."] })), _jsxs("div", Object.assign({ style: {
                                            lineHeight: 1.5,
                                            textAlign: "justify",
                                            marginBottom: 5,
                                            padding: "5px 0px 8px",
                                        } }, { children: [_jsx(FontAwesomeIcon, { icon: "exclamation-triangle", style: {
                                                    marginRight: 5,
                                                    color: "#F5A623",
                                                    fontSize: 17,
                                                    fontWeight: 900,
                                                } }), "Associated EFT PADs not marked as submitted will also be cancelled when a fee is cancelled."] }))] }), _jsx(Column, { columnStyle: "side-by-side-element" }), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element right" }, { children: _jsxs("button", Object.assign({ type: "button", className: "button submit-button red", onClick: cancelFeeCallback, disabled: processing }, { children: [_jsx(FontAwesomeIcon, { icon: "ban" }), "Cancel"] }), "cancel-purchase-order-button") }))] })) })] })) }));
};
export default CancelFeeForm;
