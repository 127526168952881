import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { router } from "@inertiajs/react";
import { Form, Column, CheckboxSelectComponent, CheckboxOption, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KratosModal from "../Modals/KratosModal";
const ModelGroups = ({ current_assigned_groups = [], investor_group_options = [], investor = "", is_model_group_open = "", callback, }) => {
    var _a;
    function assignedInvestorGroupsCallback(val) {
        setAssignedInvestorGroups(val);
    }
    // Status is Always Active since its created
    const updateInvestorGroups = (e) => {
        // call the Validation Here
        // Send data to the backend to create a new record
        const submitValues = {
            investor_groups: assignedInvestorGroups,
            investor: investor === null || investor === void 0 ? void 0 : investor.uuid,
        };
        e.preventDefault();
        router.post("/groups/assign-investor-groups", submitValues, {
            preserveScroll: true,
        });
    };
    const current_values = (current_assigned_groups) => {
        return current_assigned_groups === null || current_assigned_groups === void 0 ? void 0 : current_assigned_groups.map((obj) => obj === null || obj === void 0 ? void 0 : obj.uuid);
    };
    const [assignedInvestorGroups, setAssignedInvestorGroups] = useState((_a = current_values(current_assigned_groups)) !== null && _a !== void 0 ? _a : []);
    return (_jsx(_Fragment, { children: _jsxs(KratosModal, Object.assign({ is_open: is_model_group_open, callback: callback }, { children: [_jsx(Column, Object.assign({ columnStyle: "assign-investor-groups-form" }, { children: _jsx("div", Object.assign({ className: "center" }, { children: _jsx("h2", { children: "Assign Investor Groups" }) })) })), _jsxs(Form, Object.assign({ className: "modal-form-assign-investor-groups" }, { children: [_jsx(Column, { children: _jsx("div", Object.assign({ className: "checkbox-wrap" }, { children: _jsx(CheckboxSelectComponent, Object.assign({ label: "Investor Groups", onChange: assignedInvestorGroupsCallback, values: assignedInvestorGroups !== null && assignedInvestorGroups !== void 0 ? assignedInvestorGroups : [] }, { children: investor_group_options ? (investor_group_options) : (_jsx(CheckboxOption, { label: "", value: "" })) })) })) }), _jsx(Column, { children: _jsxs("div", Object.assign({ className: "modal-bank-account-button side-by-side-element right", style: { display: "flex" } }, { children: [_jsx("button", Object.assign({ type: "button", className: "button submit-button red", style: { marginLeft: 0 }, onClick: callback }, { children: "Cancel" }), "add_bank_account_modal_cancel_button"), _jsxs("button", Object.assign({ type: "button", className: "button submit-button", style: { marginLeft: "auto" }, onClick: updateInvestorGroups }, { children: [_jsx(FontAwesomeIcon, { icon: "save" }, "investor-form-save"), "Save"] }), "add_bank_account_modal_create_button")] })) })] }))] })) }));
};
export default ModelGroups;
