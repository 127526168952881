import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Layout from "../../Shared/Layout";
import { ActionButton, ActionButtonsCell, ActionHeader, BasicRow, CellHeader, Container, RawCell, Table, TextCell, } from "@truenorthmortgage/olympus";
import { router, usePage } from "@inertiajs/react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalUserGroups from "../../Shared/ModalGroups/ModalUserGroups";
const EditGroup = () => {
    const { investors_assigned_to_group, investor_group, CONSTANTS } = usePage()
        .props;
    const [loading, setLoading] = useState(false);
    const deleteInvestorAccountGroupLink = (investor_account) => {
        if (window.confirm("Do you really want to remove the Investor Account from the group?")) {
            router.post("/groups/remove-investor-from-group", {
                investor_account_uuid: investor_account,
                investor_group_uuid: investor_group === null || investor_group === void 0 ? void 0 : investor_group.uuid,
            }, {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
                preserveScroll: true,
            });
        }
    };
    const [isModelGroupsOpen, setIsModelGroupsOpen] = useState(false);
    const openIsModelGroupsOpen = () => {
        setIsModelGroupsOpen(true);
    };
    const closeIsModelGroupsOpen = () => {
        setIsModelGroupsOpen(false);
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "investor-landing-header" }, { children: [_jsxs(Container, Object.assign({ className: "column" }, { children: [_jsx("div", Object.assign({ className: "modal-bank-account-list" }, { children: _jsxs("div", Object.assign({ className: "full" }, { children: [_jsx("div", Object.assign({ className: "modal-bank-account-header side-by-side-element" }, { children: _jsx(ActionHeader, { title: "Assigned Investors" }, "invest-draft-form-header") })), _jsx("div", Object.assign({ className: "modal-bank-account-button side-by-side-element right" }, { children: (investor_group === null || investor_group === void 0 ? void 0 : investor_group.code) !==
                                            CONSTANTS.INVESTOR_GROUP.CODE.SYSTEM_100000_INVESTORS
                                                .value && (_jsxs(ActionButton, Object.assign({ className: "new-button", title: "Assign Investor Groups", callback: openIsModelGroupsOpen }, { children: [_jsx(FontAwesomeIcon, { icon: "plus" }), _jsx(FontAwesomeIcon, { icon: "money-check-alt", style: { marginLeft: 5 } })] }))) }))] })) })), _jsx(ModalUserGroups, { investor_group: investor_group, selected_investors: investors_assigned_to_group, callback: closeIsModelGroupsOpen, is_model_group_open: isModelGroupsOpen })] }), "investor-groups-modal"), _jsx(Container, Object.assign({ className: "column bank-account-wrapper" }, { children: _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Empty", isEmpty: (investors_assigned_to_group === null || investors_assigned_to_group === void 0 ? void 0 : investors_assigned_to_group.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "contact-detail-header section-header" }, { children: [_jsx(RawCell, { children: "Friendly ID" }), _jsx(RawCell, { children: "Name" }), _jsx(RawCell, { children: "Status" }), _jsx(RawCell, {})] })), investors_assigned_to_group === null || investors_assigned_to_group === void 0 ? void 0 : investors_assigned_to_group.map((investor) => {
                                var _a, _b, _c, _d;
                                return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: _jsx("a", Object.assign({ href: "investors/view/" + ((_a = investor === null || investor === void 0 ? void 0 : investor.investor) === null || _a === void 0 ? void 0 : _a.uuid) }, { children: (_b = investor === null || investor === void 0 ? void 0 : investor.investor) === null || _b === void 0 ? void 0 : _b.friendly_id })) }), _jsx(TextCell, { children: (_c = investor === null || investor === void 0 ? void 0 : investor.investor) === null || _c === void 0 ? void 0 : _c.name }), _jsx(TextCell, Object.assign({ className: "capitalize" }, { children: (_d = investor === null || investor === void 0 ? void 0 : investor.investor) === null || _d === void 0 ? void 0 : _d.status })), (investor_group === null || investor_group === void 0 ? void 0 : investor_group.code) !==
                                            CONSTANTS.INVESTOR_GROUP.CODE.SYSTEM_100000_INVESTORS.value ? (_jsx(ActionButtonsCell, { children: _jsx(ActionButton, Object.assign({ className: "delete-investor-account-group-link red", title: "Remove investor account from group", disabled: loading, callback: () => { var _a; return deleteInvestorAccountGroupLink((_a = investor === null || investor === void 0 ? void 0 : investor.investor) === null || _a === void 0 ? void 0 : _a.uuid); } }, { children: _jsx(FontAwesomeIcon, { icon: "trash" }) }), "action-delete-investor-account-group-link") })) : (_jsx(TextCell, {}))] }, investor === null || investor === void 0 ? void 0 : investor.uuid));
                            })] })) }), "investor-form-investor-groups")] })) }));
};
EditGroup.layout = (page) => _jsx(Layout, Object.assign({ title: "Investor" }, { children: page }));
export default EditGroup;
