import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { router, usePage } from "@inertiajs/react";
import { Column, Container, TabGroup, TabGroupTab, TextComponent, Table, CellHeader, RawCell, TextCell, BasicRow, ActionButtonsCell, ActionButton, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KratosModal from "../Modals/KratosModal";
import Loading from "../Loading";
const ModelUserGroups = ({ selected_investors = [], is_model_group_open = null, callback = null, investor_group = "", }) => {
    var _a;
    const { flash } = usePage().props;
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [search, setSearch] = useState("");
    const [investors, setInvestors] = useState([]);
    const url = usePage().url;
    const filterUsers = () => {
        if (is_model_group_open === true && (search === null || search === void 0 ? void 0 : search.length) > 0) {
            router.visit("/investors/modal-search", {
                data: { search_investors: search, url: url },
                only: ["investors"],
                onBefore: () => setLoading(true),
                onFinish: () => {
                    setLoading(false);
                    setShowTable(true);
                },
                preserveScroll: () => true,
                preserveState: () => true,
            });
        }
        else {
            setShowTable(false);
        }
    };
    const updateSearchUser = (value) => {
        setSearch(value);
    };
    const modalInvestorAction = (investor) => {
        let actions = [
            _jsx(ActionButton, Object.assign({ className: "green", title: "Add User", callback: () => addInvestor(investor) }, { children: _jsx(FontAwesomeIcon, { icon: "plus" }) }), "action-view"),
        ];
        if (selected_investors.find((investorSelected) => (investorSelected === null || investorSelected === void 0 ? void 0 : investorSelected.investor_account_uuid) === investor.uuid)) {
            actions = [
                _jsx(ActionButton, Object.assign({ className: "red", title: "Delete User", callback: () => removeInvestor(investor) }, { children: _jsx(FontAwesomeIcon, { icon: "link-slash" }) }), "action-delete"),
            ];
        }
        return actions;
    };
    const removeInvestor = (investor) => {
        router.post("/groups/remove-investor-from-group", {
            investor_account_uuid: investor === null || investor === void 0 ? void 0 : investor.uuid,
            investor_group_uuid: investor_group === null || investor_group === void 0 ? void 0 : investor_group.uuid,
        }, {
            onStart: () => setLoading(true),
            onFinish: () => {
                setLoading(false); // Stop loading
                filterUsers(); // Call filterUsers
            },
            preserveScroll: true,
        });
    };
    const addInvestor = (investor) => {
        router.post("/groups/assign-investor-to-group", {
            investor_account_uuid: investor === null || investor === void 0 ? void 0 : investor.uuid,
            investor_group: investor_group !== null && investor_group !== void 0 ? investor_group : {},
        }, {
            onStart: () => setLoading(true),
            onFinish: () => {
                setLoading(false); // Stop loading
                filterUsers(); // Call filterUsers
            },
            preserveScroll: true,
        });
    };
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterUsers();
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);
    useEffect(() => {
        var _a, _b;
        setInvestors((_b = (_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.investors) !== null && _b !== void 0 ? _b : []);
    }, [(_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.investors]);
    return (_jsx(_Fragment, { children: _jsxs(KratosModal, Object.assign({ is_open: is_model_group_open, callback: callback }, { children: [_jsx(Column, Object.assign({ columnStyle: "assign-investor-groups-form" }, { children: _jsx("div", Object.assign({ className: "center" }, { children: _jsx("h2", { children: "Assign Users To Investor Groups" }) })) })), _jsx(Container, { children: _jsx(TabGroup, { children: _jsxs(TabGroupTab, Object.assign({ name: "Find Existing" }, { children: [_jsx(TextComponent, { label: "Friendly ID, Email Address, or Name", className: "search_icon", onChange: updateSearchUser, formData: search }, "search"), loading === true && _jsx(Loading, { style: { width: "50px" } }), loading !== true && showTable && (_jsxs(Table, Object.assign({ showSearchBar: false, title: "", className: "column", emptyText: "No users found", isEmpty: (investors === null || investors === void 0 ? void 0 : investors.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "users-header section-header" }, { children: [_jsx(RawCell, { children: "ID" }), _jsx(RawCell, { children: "First Name" }), _jsx(RawCell, { children: "Last Name" }), _jsx(RawCell, { children: "Email" }), _jsx(TextCell, {})] })), investors &&
                                            (investors === null || investors === void 0 ? void 0 : investors.map((investor, index) => {
                                                var _a, _b, _c;
                                                return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: index + 1 }), _jsx(TextCell, { children: (_a = investor === null || investor === void 0 ? void 0 : investor.name) !== null && _a !== void 0 ? _a : "" }), _jsx(TextCell, { children: (_b = investor === null || investor === void 0 ? void 0 : investor.type) !== null && _b !== void 0 ? _b : "" }), _jsx(TextCell, { children: (_c = investor === null || investor === void 0 ? void 0 : investor.friendly_id) !== null && _c !== void 0 ? _c : "" }), _jsx(ActionButtonsCell, { children: modalInvestorAction(investor) })] }, investor.uuid));
                                            }))] })))] })) }) })] })) }));
};
export default ModelUserGroups;
