import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Layout from "../../Shared/Layout";
import { ActionButton, ActionButtonsCell, BasicRow, CellHeader, Container, RawCell, Table, TextCell, } from "@truenorthmortgage/olympus";
import { router, usePage } from "@inertiajs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Group = () => {
    const { groups } = usePage().props;
    const editCallback = (val) => {
        router.get("/groups/edit/" + val);
    };
    return (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ className: "investment-landing-header" }, { children: _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Empty", isEmpty: (groups === null || groups === void 0 ? void 0 : groups.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "contact-detail-header section-header" }, { children: [_jsx(RawCell, { children: "Name" }), _jsx(RawCell, { children: "Description" }), _jsx(RawCell, { children: "Status" }), _jsx(RawCell, {})] })), groups === null || groups === void 0 ? void 0 : groups.map((group) => (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: group === null || group === void 0 ? void 0 : group.name }), _jsx(TextCell, { children: group === null || group === void 0 ? void 0 : group.description }), _jsx(TextCell, Object.assign({ className: "capitalize" }, { children: group === null || group === void 0 ? void 0 : group.status })), _jsx(ActionButtonsCell, { children: _jsx(ActionButton, Object.assign({ id: "view-" + (group === null || group === void 0 ? void 0 : group.uuid), title: "View", callback: () => editCallback(group === null || group === void 0 ? void 0 : group.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "eye" }) }), "action-view") })] }, group === null || group === void 0 ? void 0 : group.uuid)))] })) }), "investment-landing-header") }));
};
Group.layout = (page) => _jsx(Layout, Object.assign({ title: "Settings" }, { children: page }));
export default Group;
