import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Layout from "../../Shared/Layout";
import { ActionButton, ActionButtonsCell, BasicRow, CellHeader, Container, RawCell, Table, TextCell, } from "@truenorthmortgage/olympus";
import { router, usePage } from "@inertiajs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNumberToMoneyFormat } from "../../../utils/utils";
const Fee = () => {
    const { fees } = usePage().props;
    const viewFeeDetailsCallback = (uuid) => {
        router.get("/fees/view/" + uuid);
    };
    return (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ className: "fee-landing-header" }, { children: _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Empty", isEmpty: (fees === null || fees === void 0 ? void 0 : fees.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "contact-detail-header section-header" }, { children: [_jsx(RawCell, { children: "Name" }), _jsx(RawCell, { children: "Description" }), _jsx(RawCell, { children: "Amount" }), _jsx(RawCell, { children: "Start Date" }), _jsx(RawCell, { children: "End Date" }), _jsx(RawCell, { children: "Status" }), _jsx(RawCell, {})] })), fees === null || fees === void 0 ? void 0 : fees.map((fee) => {
                        var _a;
                        return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: fee === null || fee === void 0 ? void 0 : fee.name }), _jsx(TextCell, { children: fee === null || fee === void 0 ? void 0 : fee.description }), _jsxs(TextCell, { children: ["$", formatNumberToMoneyFormat((fee === null || fee === void 0 ? void 0 : fee.amount) ? (fee === null || fee === void 0 ? void 0 : fee.amount) / 100 : 0)] }), _jsx(TextCell, { children: fee === null || fee === void 0 ? void 0 : fee.start_date }), _jsx(TextCell, { children: (_a = fee === null || fee === void 0 ? void 0 : fee.end_date) !== null && _a !== void 0 ? _a : "-" }), _jsx(TextCell, Object.assign({ className: "capitalize" }, { children: fee === null || fee === void 0 ? void 0 : fee.status })), _jsx(ActionButtonsCell, { children: _jsx(ActionButton, Object.assign({ id: "view-" + (fee === null || fee === void 0 ? void 0 : fee.uuid), title: "View", callback: () => viewFeeDetailsCallback(fee === null || fee === void 0 ? void 0 : fee.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "eye" }) }), "action-view") })] }, fee === null || fee === void 0 ? void 0 : fee.uuid));
                    })] })) }), "fee-landing-header") }));
};
Fee.layout = (page) => _jsx(Layout, Object.assign({ title: "Fees" }, { children: page }));
export default Fee;
